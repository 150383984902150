import _ from 'lodash';
import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { Chart } from '../../helpers';
import { useSelector } from 'react-redux';
import { getStatusDropdown } from '../../../selectors/dropdowns';
import { colors } from '@commonsku/styles';

const POsByStatusChart = React.memo((props) => {
  const {
    order_type='PURCHASE ORDER',
    report_type='',
    height="350px",
    totals={},
    onClickColumn,
  } = props;

  const poStatuses = useSelector(
    s => _.orderBy(
      getStatusDropdown(s, { order_type: order_type}),
      ['flow_order'],
      ['desc']
    )
    .map(v => v.status_name)
    .filter(v => ![
      'Billed',
      'Closed',
      'Follow Up',
      'Problem',
      'No Proof Required',
      'Proofing Complete',
    ].includes(v))
  );

  const findStatus = React.useCallback((status_name) => {
    for (const status of poStatuses) {
      if (status.toLowerCase() === status_name.toLowerCase()) {
        return status;
      }
    }

    return null;
  }, [poStatuses]);

  function getStatusName(status) {
    return status.slice(4)
      .split('_')
      .map(v => v.charAt(0).toUpperCase() + v.slice(1))
      .join(' ')
      .trim();
  }

  const chartData = React.useMemo(() => {
    const statuses = poStatuses.map(s => s.toLowerCase());
      return _.keys(totals)
        .filter(
          k => statuses.includes(getStatusName(k).toLowerCase())
        )
        .map(k => {
          const statusName = getStatusName(k);
          return {
            status_name: findStatus(statusName) || statusName,
            total: parseInt(totals[k] || 0),
            order_type: order_type,
            report_type,
            columnColor: colors.primary1['50']
          };
        });
  }, [totals, poStatuses, order_type, report_type, findStatus]);

  return (
    <Chart
      type="bar"
      width="100%"
      data={chartData}
      onChartReady={poStatusColumnChart({
        onClickColumn,
        category: 'status_name',
        value: 'total',
      })}
      height={height}
    />
  );
});

export function poStatusColumnChart({ onClickColumn, value = "value", category = "category", }) {
  return function (chart, data) {

    /** @type {am4charts.CategoryAxis}  */
    const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = category;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.line.strokeOpacity = 0;
    categoryAxis.tooltip.disabled = true;

    categoryAxis.renderer.labels.template.events.on("hit", function (ev) {
      onClickColumn && onClickColumn(ev.target.dataItem.dataContext);
    }, this);

    const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.line.strokeOpacity = 0;

    /** @type {am4charts.ColumnSeries}  */
    const columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.dataFields.valueX = value;
    columnSeries.dataFields.categoryY = category;
    columnSeries.columns.template.strokeWidth = 0;
    columnSeries.tooltip.disabled = false;
    columnSeries.tooltipText = "{categoryY}: {valueX}";
    columnSeries.columns.template.propertyFields.fill = "columnColor";

    chart.maskBullets = false;
    chart.paddingRight = 30;
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true;
    chart.cursor.tooltipText = "{categoryY}: {valueX}";

    columnSeries.columns.template.events.on("hit", function (ev) {
      onClickColumn && onClickColumn(ev.target.dataItem.dataContext);
    }, this);

    columnSeries.tooltip.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    columnSeries.tooltip.keepTargetHover = true;
    columnSeries.tooltip.events.on("hit", function (ev) {
      onClickColumn && onClickColumn(ev.target.dataItem.dataContext);
    }, this);

  };
}

export default POsByStatusChart;
